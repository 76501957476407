export const messages = {
    en: {
        intro: "A service made for renewing Facebook Marketplace ads with ease",
        intro2: "* At this time we don't support Vehicles listings",
        header1: "Renew your Marketplace ads with a few clicks",
        header2: "using our service",
        choose_plan: "Choose Your Plan",
        month: "Month",
        starter_li_1: "Renew up to 10 ads",
        starter_li_2: "1 computer license",
        starter_li_3: "Email Support",
        pro_li_strong: "Unlimited ad renews for the first 100 subscribers",
        pro_li_1: "Renew up to 50 ads",
        pro_li_2: "3 computer license",
        pro_li_3: "Priority Email Support",
        premium_li_1: "Unlimited ad renewal",
        premium_li_2: "3 computer license",
        premium_li_3: "Dedicated Support",
        aboutUsHeader: "About Us",
        aboutUsContent: "We provide a user friendly software to help you renew your Facebook Marketplace Ads. Our goal is to help your ads stay relevant, and let you manage them with ease. For any inquiries, please contact us at",
    },
    he: {
        intro: "שירות עבור חידוש מודעות בפייסבוק מרקטפלייס בקלות",
        intro2: "נכון לעכשיו אנו לא תומכים במודעות מסוג כלי רכב *",
        header1: "חדשו את מודעות המרקטפלייס שלכם במספר קליקים בודדים",
        header2: "באמצעות השירות שלנו",
        choose_plan: "בחרו תכנית",
        month: "חודש",
        starter_li_1: "חידוש עד 10 מודעות",
        starter_li_2: "רישיון למחשב 1",
        starter_li_3: "תמיכה במייל",
        pro_li_strong: "חידוש ללא מגבלת מודעות ל100 מנויים הראשונים",
        pro_li_1: "חידוש עד 50 מודעות",
        pro_li_2: "רישיון ל3 מחשבים",
        pro_li_3: "תמיכה מועדפת",
        premium_li_1: "חידוש ללא מגבלת מודעות",
        premium_li_2: "רישיון ל3 מחשבים",
        premium_li_3: "תמיכה לייב",
        aboutUsHeader: "עלינו",
        aboutUsContent: "אנו מספקים תוכנה ידידותית שתעזור לכם לחדש את המודעות שלכם בפייסבוק מרקטפלייס. המטרה שלנו היא לעזור למודעות שלכם להישאר רלוונטיות ולאפשר לכם לנהל אותן בקלות. לכל שאלה, צרו קשר בעמוד האנסטגרם שלנו",
    },
  };
  