import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import { messages } from './locales';

function detectLanguageFromBrowser() {
    const userLang = navigator.language || navigator.userLanguage; // e.g., "he-IL", "en-US"
    return userLang.startsWith('he') ? 'he' : 'en'; // Hebrew if the language starts with "he"
  }

// Determine user's location and set default language
async function detectLanguage() {
    try {
      const response = await fetch("https://ipinfo.io/json?token=5422185868e3a9");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
    //   console.log('Geolocation API response:', data.country);
      return data.country === 'IL' ? 'he' : detectLanguageFromBrowser();
    } catch (error) {
      console.error('Error detecting language:', error);
      return detectLanguageFromBrowser();
    }
  }

(async () => {
  const locale = await detectLanguage();

  const i18n = createI18n({
    locale,
    fallbackLocale: 'en', // Fallback to English if a translation is missing
    messages,
  });

  const app = createApp(App);
  app.use(i18n);
  app.mount('#app');
})();
